<!-- =========== MAIN PAGE CONTAINER ============== -->
<div
	class="main-container"
	style="
		display: flex;
		flex-direction: column;
		height: 100%;
		background-image: url('../../../../assets/images/Trb-latest-svg/Background-img.svg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	"
>
	<div
		*ngIf="headerWithImage"
		class="header-container"
		style="
			width: inherit;
			height: 20%;
			background-image: url('../../../../assets/newlayout/navbar-image/maldives-image-min.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			z-index: 1;
		"
	>
		<app-top-navbar [sideBarExpanded]="sideBarExpanded" (onTogglerClick)="toggleSideNav()"></app-top-navbar>
	</div>

	<div *ngIf="!headerWithImage" class="header-container">
		<app-top-navbar [sideBarExpanded]="sideBarExpanded" (onTogglerClick)="toggleSideNav()"></app-top-navbar>
	</div>

	<div *ngIf="showBreadcrumb" style="height: 50px; margin: 20px 30px 20px 30px">
		<app-breadcrumb></app-breadcrumb>
	</div>

	<div class="body-container">
		<!-- <app-to-do-list-action-model></app-to-do-list-action-model> -->
		<ng-container #dynamicComponent></ng-container>
	</div>

	<!-- ============ FOOTER COMPONENT STYLE ================== -->
	<app-footer></app-footer>
</div>
