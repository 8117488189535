import { MsalService } from '@azure/msal-angular';
import { InternalAppStorageService } from '../../../../../goldstar-share/src/app/services/internal-app-storage.service';
import { Injectable } from '@angular/core';
import { Observable, catchError, mergeMap, of } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { LocalStorageService } from '../../../../../goldstar-share/src/app/services/local.storage.service';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private readonly appTokenKey = 'internalAPPTokenExpiryValue';
	private readonly appToken = 'internalAppToken';
	constructor(
		private storageService: InternalAppStorageService,
		private authService: MsalService,
		private localStorageService: LocalStorageService
	) {}

	/**
	 * Acquires a token either from cache or a fresh token from AD
	 * @returns
	 */
	acquireToken(): Observable<string> {
		const tokenExpiryFromCache = this.storageService.tryLoadItem<number>(this.appTokenKey);
		if (!tokenExpiryFromCache || tokenExpiryFromCache == null) {
			return this.renewToken();
		} else {
			const current_time = Math.round(Date.now() / 1000);
			if (tokenExpiryFromCache <= current_time) {
				return this.renewToken();
			} else {
				const tokenFromCache = this.storageService.tryLoadItem<string>(this.appToken);
				return of(tokenFromCache ?? '');
			}
		}
	}

	/**
	 * Fetches a new token from the auth provider
	 * @returns
	 */
	private renewToken(): Observable<string> {
		debugger;
		let accessToken = '';
		const accounts = this.authService.instance.getAllAccounts();
		if (accounts?.length) {
			const accountInfo = accounts[0];
			const fetchTokenRequest = {
				scopes: ['user.read'],
				account: accountInfo,
			};
			this.clearUserToken();
			return this.authService
				.acquireTokenSilent(fetchTokenRequest)
				.pipe(
					mergeMap((accessTokenResponse) => {
						const accessToken = accessTokenResponse.accessToken;
						const decodedJWTToken = jwtDecode(accessToken)!;
						this.storageService.storeItem<number>(this.appTokenKey, decodedJWTToken.exp ?? 0);
						this.storageService.storeItem<string>(this.appToken, accessToken);
						return of(accessToken);
					})
				)
				.pipe(
					catchError((error: any) => {
						console.log('Error while acquiring token silent');
						return of(accessToken);
					})
				);
		}
		return of(accessToken);
	}

	clearUserToken() {
		const allStorageKeys = this.localStorageService.allKeys();
		const allTokenStorageKeys = allStorageKeys.filter((key) => key.includes('msal') || key.includes('login.windows.net'));
		if (allTokenStorageKeys.length > 0) {
			allTokenStorageKeys.forEach((key: string) => {
				if (this.localStorageService.itemExists(key)) {
					this.localStorageService.removeItem(key);
				}
			});
		}
	}
}
