import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
	DeleteRequest,
	EntityGetRequest,
	InternalPermission,
	InternalPermissionListItem,
	InternalPermissionTypeItem,
	InternalTitlePermissionListItem,
	WikiPermissionGetRequest,
	WikiRoleXPermissionItem,
} from '../api-data/ng-openapi-gen-next/models';
import { InternalUserPermissionListItem } from '../api-data/ng-openapi-gen-next/models/internal-user-permission-list-item';
import { InternalUserPermissionRequest } from '../api-data/ng-openapi-gen-next/models/internal-user-permission-request';
import { ApiService } from '../api-data/ng-openapi-gen-next/services';
import { ResultHelper } from '../common/result-extension';
import { Result } from '../models/models';

@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	public loadedUserPermissionList: InternalTitlePermissionListItem[] | undefined;
	public loadedUserId!: string;

	constructor(private apiV2: ApiService) {}

	public permissionMap: Map<string | undefined, string> = new Map<string | undefined, string>([
		// Referral Unique Menu Permission's
		['ReferralMenuGroup.ConfigureIncentive.FX', '/dashboard/referral/configureIncentive/fx'],
		['ReferralMenuGroup.Dashboard.FX', '/dashboard/referral/dashboard/viewFx'],
		['ReferralMenuGroup.Assign.FX', '/dashboard/referral/assign/fx'],

		['ReferralMenuGroup.ConfigureIncentive.Insurance', '/dashboard/referral/configureIncentive/insurance'],
		['ReferralMenuGroup.Dashboard.Insurance', '/dashboard/referral/dashboard/viewInsurance'],
		['ReferralMenuGroup.Assign.Insurance', '/dashboard/referral/assign/insurance'],

		['ReferralMenuGroup.ConfigureIncentive.Merchant', '/dashboard/referral/configureIncentive/merchant'],
		['ReferralMenuGroup.Dashboard.Merchant', '/dashboard/referral/dashboard/viewMerchant'],
		['ReferralMenuGroup.Assign.Merchant', '/dashboard/referral/assign/merchant'],

		['ReferralMenuGroup.ConfigureIncentive.Mortgage', '/dashboard/referral/configureIncentive/mortgage'],
		['ReferralMenuGroup.Dashboard.Mortgage', '/dashboard/referral/dashboard/viewMortgage'],
		['ReferralMenuGroup.Assign.Mortgage', '/dashboard/referral/assign/mortgage'],

		['ReferralMenuGroup.ConfigureIncentive.Treasury', '/dashboard/referral/configureIncentive/treasury'],
		['ReferralMenuGroup.Dashboard.Treasury', '/dashboard/referral/dashboard/viewTreasury'],
		['ReferralMenuGroup.Assign.Treasury', '/dashboard/referral/assign/treasury'],

		['ReferralMenuGroup.ConfigureIncentive.Wealth', '/dashboard/referral/configureIncentive/wealth'],
		['ReferralMenuGroup.Dashboard.Wealth', '/dashboard/referral/dashboard/viewWealth'],
		['ReferralMenuGroup.Assign.Wealth', '/dashboard/referral/assign/wealth'],

		['ReferralMenuGroup.ConfigureIncentive.Employee', '/dashboard/referral/configureIncentive/employee'],
		['ReferralMenuGroup.Dashboard.Employee', '/dashboard/referral/dashboard/viewEmployee'],
		['ReferralMenuGroup.Assign.Employee', '/dashboard/referral/assign/employee'],

		['ReferralMenuGroup.ConfigureIncentive.CreditCard', '/dashboard/referral/configureIncentive/creditCard'],
		['ReferralMenuGroup.Dashboard.CreditCard', '/dashboard/referral/dashboard/viewCreditCard'],
		['ReferralMenuGroup.Assign.CreditCard', '/dashboard/referral/assign/creditCard'],

		['ReferralMenuGroup.ConfigureIncentive.InternationalPrivateClient', '/dashboard/referral/configureIncentive/internationalPrivateClient'],
		['ReferralMenuGroup.Dashboard.InternationalPrivateClient', '/dashboard/referral/dashboard/viewInternationalPrivateClient'],
		['ReferralMenuGroup.Assign.InternationalPrivateClient', '/dashboard/referral/assign/internationalPrivateClient'],

		['ReferralMenuGroup.ConfigureIncentive.DomesticPrivateClient', '/dashboard/referral/configureIncentive/domesticPrivateClient'],
		['ReferralMenuGroup.Dashboard.DomesticPrivateClient', '/dashboard/referral/dashboard/viewDomesticPrivateClient'],
		['ReferralMenuGroup.Assign.DomesticPrivateClient', '/dashboard/referral/assign/domesticPrivateClient'],

		['ReferralMenuGroup.ConfigureIncentive.DepositAccount', '/dashboard/referral/configureIncentive/depositAccount'],
		['ReferralMenuGroup.Dashboard.DepositAccount', '/dashboard/referral/dashboard/viewDepositAccount'],
		['ReferralMenuGroup.Assign.DepositAccount', '/dashboard/referral/assign/depositAccount'],

		['ReferralMenuGroup.ConfigureIncentive.Loan', '/dashboard/referral/configureIncentive/loan'],
		['ReferralMenuGroup.Dashboard.Loan', '/dashboard/referral/dashboard/viewLoan'],
		['ReferralMenuGroup.Assign.Loan', '/dashboard/referral/assign/loan'],

		// Referral Employee Common Menu Pemission's
		['ReferralMenuGroup.Dashboard', '/dashboard/referral'],
		['ReferralMenuGroup.MyReferrals', '/dashboard/referral/viewMyReferrals'],

		['ReferralMenuGroup.AddFX', '/dashboard/referral/fx'],
		['ReferralMenuGroup.AddInsurance', '/dashboard/referral/insurance'],
		['ReferralMenuGroup.AddMerchant', '/dashboard/referral/merchant'],
		['ReferralMenuGroup.AddMortgage', '/dashboard/referral/mortgage'],
		['ReferralMenuGroup.AddTreasury', '/dashboard/referral/treasury'],
		['ReferralMenuGroup.AddWealth', '/dashboard/referral/wealth'],
		['ReferralMenuGroup.AddInternationalPrivateClient', '/dashboard/referral/internationalPrivateClient'],
		['ReferralMenuGroup.AddDomesticPrivateClient', '/dashboard/referral/domesticPrivateClient'],
		['ReferralMenuGroup.AddDepositAccount', '/dashboard/referral/depositAccount'],
		['ReferralMenuGroup.AddLoan', '/dashboard/referral/loan'],

		['ReferralMenuGroup.EditFX', '/dashboard/referral/edit/fx'],
		['ReferralMenuGroup.EditInsurance', '/dashboard/referral/edit/insurance'],
		['ReferralMenuGroup.EditTreasury', '/dashboard/referral/edit/treasury'],
		['ReferralMenuGroup.EditMortgage', '/dashboard/referral/edit/mortgage'],
		['ReferralMenuGroup.EditWealth', '/dashboard/referral/edit/wealth'],
		['ReferralMenuGroup.EditMerchant', '/dashboard/referral/edit/merchant'],
		['ReferralMenuGroup.EditEmployee', '/dashboard/referral/edit/employee'],
		['ReferralMenuGroup.EditCreditCard', '/dashboard/referral/edit/creditCard'],
		['ReferralMenuGroup.EditInternationalPrivateClient', '/dashboard/referral/edit/internationalPrivateClient'],
		['ReferralMenuGroup.EditDepositAccount', '/dashboard/referral/edit/depositAccount'],
		['ReferralMenuGroup.EditDomesticPrivateClient', '/dashboard/referral/edit/domesticPrivateClient'],

		['ReferralMenuGroup.EditLoan', '/dashboard/referral/edit/loan'],

		//Role
		['AdminMenuGroup.AddRole', '/dashboard/role/add'],
		['AdminMenuGroup.EditRole', '/dashboard/role/edit'],
		['AdminMenuGroup.RoleList', '/dashboard/role/list'],
		['AdminMenuGroup.DeleteRole', '/dashboard/role/delete'],
		['AdminMenuGroup.AddRole.UpdateSystemId', '/dashboard/role/updateSystemId'],

		//User
		['AdminMenuGroup.UserList', '/dashboard/user/list'],
		['AdminMenuGroup.AddUser', '/dashboard/user/add'],
		['AdminMenuGroup.EditUser', '/dashboard/user/edit'],
		['AdminMenuGroup.DeleteUser', '/dashboard/user/delete'],

		//User Group
		['AdminMenuGroup.UserGroupList', '/dashboard/user-group/list'],
		['AdminMenuGroup.UserGroupAddOrEdit', '/dashboard/user-group/addOrEdit'],

		// Permission
		['AdminMenuGroup.AddPermission', '/dashboard/role/permissionadd'],
		['AdminMenuGroup.EditPermission', '/dashboard/role/permissionedit'],
		['AdminMenuGroup.PermissionList', '/dashboard/role/permissionlist'],
		['AdminMenuGroup.DeletePermission', '/dashboard/role/deletepermission'],

		//GS Job Management
		['AdminMenuGroup.Jobs.Management', '/dashboard/gsJobStatus/viewJobManagement'],
		['AdminMenuGroup.Jobs.View', '/dashboard/gsJobStatus/viewJobStatusDashboard'],

		// To be removed later
		['AllServices', 'allServices'],

		// All permissions
		['*', '/allow'],

		// User Impersonation Permission
		['AdminMenuGroup.AllowUserImpersonation', '/user/allowImpersonation'],

		//Training Admin Permission
		['TrainingAdmin.Training', '/dashboard/training'],
		['TrainingAdmin.TrainingEvent', '/dashboard/trainingevent'],
		['TrainingAdmin.TrainingList', '/dashboard/traininglist'],

		// Training User Permission
		['TrainingAdmin.Training', '/dashboard/trainingregistration'],
		['TrainingAdmin.TrainingList', '/dashboard/traininglistregistration'],

		//Performance Management Permission
		['PerformanceManagementUser.Dashboard', '/dashboard/project-management/title'],
		['PerformanceManagementUser.Project', '/dashboard/project-management/project'],
		['PerformanceManagementUser.Task', '/dashboard/project-management/taskAdd'],
		['PerformanceManagementUser.MonthlyReview', '/dashboard/project-management/monthlyReview'],
		['PerformanceManagementUser.DocumentList', '/dashboard/monthlyReview/documents'],
		['PerformanceManagementUser.DocumentCreateOrEdit', '/dashboard/monthlyReview/documents/view'],

		//Rate Adjustment Permission
		['RateAdjustment.WSJPrime', '/dashboard/adjustRatesWidget'],
		['RateAdjustment.CreditCard', '/dashboard/adjustRatesWidget'],
		['RateAdjustment.CDs', '/dashboard/adjustRatesWidget'],
		['RateAdjustment.Report', '/dashboard/adjustRatesWidget'],

		//Relationship Analysis Permission
		['RelationshipAnalysis', '/dashboard/relationshipAnalysis'],

		//Private Banking Permission
		['PrivateBanking.Management', '/dashboard/privateBanking'],
		['PrivateBanking.Management', '/dashboard/privateBanking/relationshipView'],
		['PrivateBanking.Management', '/dashboard/privateBanking/myGoals'],
		['PrivateBanking.Management', '/dashboard/privateBanking/teamGoals'],
		['PrivateBanking.RelationshipManagement', '/dashboard/privateBanking'],
		['PrivateBanking.RelationshipManagement', '/dashboard/privateBanking/relationshipView'],
		['PrivateBanking.RelationshipManagement', '/dashboard/privateBanking/myGoals'],

		//Over Draft Program Permission
		['OverdraftProgram', '/dashboard/overdraftProgram'],
		['OverdraftProgramRules.View', '/dashboard/overdraftProgram/rules'],
		['OverdraftProgramRules.Edit', '/dashboard/overdraftProgram/rules'],
		['OverdraftNoticeTemplates', '/dashboard/overdraftProgram/noticeTemplates'],
		['OverdraftProgram.Report', '/dashboard/overdraftProgram/report'],
		['OverdraftProgram.Portal', '/dashboard/overdraftProgram/portal'],

		//Account Application Status Permission
		['AccountApplicationDecisioning.Dashboard', '/dashboard/accountApplicationDecisioning'],
		['AccountApplicationDecisioning.Summary', '/dashboard/accountApplicationDecisioning/summary'],

		//Account Application Decisioning
		['AccountApplicationDecisioning.Access', '/dashboard/accountApplicationDecisioning'],
		['AccountApplicationDecisioning.Summary', '/dashboard/accountApplicationDecisioning/summary'],

		//Work Location
		['WorkLocations.AddEdit', '/dashboard/work-location/admin'],
		['WorkLocations.View', '/dashboard/work-location/user'],

		//To Do
		['ToDoEvent', '/dashboard/view-to-do-event'],
		['CreateEvent', '/dashboard/create-to-do-event'],

		//Reports
		['Reporting.MLOListing', '/reports/mlo-listing'],

		//Feedback
		['QuestionCommentSuggestion.Add', '/dashboard/add-question-comment-suggestion'],
		['QuestionCommentSuggestion.View', '/dashboard/view-question-comment-suggestion'],

		//Campaign Management Permission
		['CampaignManagement', '/dashboard/campaignManagement'],

		//Mailchimp
		['CampaignManagement.AddMailchimpCampaign', '/dashboard/mailchimpCampaign/addMailchimpCampaign'],
		['CampaignManagement.ViewMailchimpCampaign', '/dashboard/mailchimpCampaign/viewMailchimpCampaign'],

		//ServiceDesk
		['ServiceDeskMenuGroup.FacilitiesServiceAssignTicket', '/dashboard/serviceDesk/assign/facilities'],
		['ServiceDeskMenuGroup.MarketingServiceAssignTicket', '/dashboard/serviceDesk/assign/marketing'],
		['ServiceDeskMenuGroup.BranchAdminServiceAssignTicket', '/dashboard/serviceDesk/assign/branchAdmin'],

		['ServiceDeskMenuGroup.FacilitiesServiceTicketDashboard', '/dashboard/serviceDesk/dashboard/viewFacilities'],
		['ServiceDeskMenuGroup.MarketingServiceTicketDashboard', '/dashboard/serviceDesk/dashboard/viewMarketing'],
		['ServiceDeskMenuGroup.BranchAdminServiceTicketDashboard', '/dashboard/serviceDesk/dashboard/viewBranchAdmin'],

		['ServiceDeskMenuGroup.Facilities.EditRequestor', '/dashboard/serviceDesk/editRequestor/facilities'],
		['ServiceDeskMenuGroup.Marketing.EditRequestor', '/dashboard/serviceDesk/editRequestor/marketing'],
		['ServiceDeskMenuGroup.BranchAdmin.EditRequestor', '/dashboard/serviceDesk/editRequestor/branchAdmin'],

		//Regional Report
		['ReferralMenuGroup.RGVReport', '/dashboard/referral/dashboard/viewRGVRegionalReport'],
		['ReferralMenuGroup.DFWReport', '/dashboard/referral/dashboard/viewDFWRegionalReport'],
		['ReferralMenuGroup.HillCountryReport', '/dashboard/referral/dashboard/viewHillCountryRegionalReport'],
		['ReferralMenuGroup.HoustonReport', '/dashboard/referral/dashboard/viewHoustonRegionalReport'],
		['ReferralMenuGroup.ConsolidatedReporting', '/dashboard/referral/consolidatedReporting'],

		//vehicle lien
		['AdminMenuGroup.VehicleLien.View', '/dashboard/vehicleLien/viewVehicleLien'],
		['AdminMenuGroup.VehicleLien.AddEdit', '/dashboard/vehicleLien/addEditVehicleLien'],
	]);

	/*  
      Initialize the permission service by loading all the permission for the 
      current user. 
  */
	public async init(userEmail?: string) {
		/* 
			If the passed user id is same as the loaded user then the loaded permission list is returned.
		 */
		if (this.loadedUserId == userEmail && this.loadedUserPermissionList && this.loadedUserPermissionList.length > 0) {
			return;
		}
		const allPermissionsForUser: InternalUserPermissionRequest = {
			userId: userEmail,
		};
		const allPermissionForUserResponse = await lastValueFrom(this.apiV2.allUserPermission({ body: allPermissionsForUser }));
		if (allPermissionForUserResponse.isSuccess && allPermissionForUserResponse.data?.items) {
			this.loadedUserPermissionList = allPermissionForUserResponse.data?.items;
		} else {
			this.loadedUserPermissionList = [];
		}
	}

	/**
	 * Fetches all permissions from data store (This is not user specific, but fetches all permissions)
	 * @returns
	 */
	public async fetchAllPermissions(): Promise<Result<InternalPermissionListItem[]>> {
		try {
			let entityGetRequest: EntityGetRequest = {
				searchFilter: [{ searchOption: 'ALL' }],
				sortColumns: [{ columnName: 'systemCode', isAscending: true }],
				pageNumber: 1,
				pageSize: 1000,
			};
			return await lastValueFrom(this.apiV2.permissionList({ body: entityGetRequest })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<InternalPermissionListItem[]>('Failed to fetch roles');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<InternalPermissionListItem[]>(error);
		}
	}

	/**
	 * Adds a new permission in database
	 * @param request
	 * @returns
	 */
	public async addPermission(request: InternalPermission): Promise<Result<string>> {
		try {
			return await lastValueFrom(this.apiV2.permissionAdd({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				} else {
					return ResultHelper.failedResponse<string>('Failed to save permission');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<string>(error);
		}
	}

	/**
	 * Adds a new permission in database
	 * @param request
	 * @returns
	 */
	public async updatePermission(request: InternalPermission): Promise<Result<string>> {
		try {
			return await lastValueFrom(this.apiV2.permissionUpdate({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				} else {
					return ResultHelper.failedResponse<string>('Failed to save permission');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<string>(error);
		}
	}

	public async userHasPermission(value: string | undefined, userId?: string): Promise<boolean> {
		// IF LOADED PERMISSION LIST IS UNDEFINED, THEN USER PERMISSION LIST IS NOT LOADED, ONLY IN THOSE CASES RE LOAD THE PERMISSIONS
		if (!this.loadedUserPermissionList) {
			if (userId) {
				await this.init(userId);
			}
		}

		// if user has master permission (e.g. *), then allow true always
		if (this.loadedUserPermissionList && this.loadedUserPermissionList.find((x) => x.permissionSystemID == '*')) {
			return true;
		}

		if (this.loadedUserPermissionList) {
			let matchingPermissionValues = this.loadedUserPermissionList
				.map((userPermission: InternalUserPermissionListItem) => {
					return this.permissionMap.get(userPermission.permissionSystemID);
				})
				.filter((x) => x != undefined);

			let hasMatchingPermission: boolean = false;
			matchingPermissionValues.forEach((permissionValue: string | undefined) => {
				if (hasMatchingPermission) return;
				if (!hasMatchingPermission && permissionValue && value) {
					hasMatchingPermission = permissionValue.includes(value);
				}
			});
			return hasMatchingPermission;
		}
		return false;
	}

	public async userHasDevPermission(userId?: string): Promise<boolean> {
		// IF LOADED PERMISSION LIST IS UNDEFINED, THEN USER PERMISSION LIST IS NOT LOADED, ONLY IN THOSE CASES RE LOAD THE PERMISSIONS
		if (!this.loadedUserPermissionList) {
			if (userId) {
				await this.init(userId);
			}
		}

		// if user has master permission (e.g. *), then allow true always
		if (this.loadedUserPermissionList && this.loadedUserPermissionList.find((x) => x.permissionSystemID == '*')) {
			return true;
		}
		return false;
	}

	/**
	 * Resolves a permission based on the MENU GUID
	 * @param value
	 * @param emailId
	 * @returns
	 */
	public async userPermissionCover(value: string | undefined, emailId?: string): Promise<boolean> {
		// IF LOADED PERMISSION LIST IS UNDEFINED, THEN USER PERMISSION LIST IS NOT LOADED, ONLY IN THOSE CASES RE LOAD THE PERMISSIONS
		if (!this.loadedUserPermissionList) {
			await this.init(emailId);
		}

		// if user has master permission (e.g. *), then allow true always
		if (this.loadedUserPermissionList && this.loadedUserPermissionList.find((x) => x.permissionSystemID == '*')) {
			return true;
		}

		const operationCoverPermission = this.loadedUserPermissionList?.find((x) => x.menuGUID === value);
		if (operationCoverPermission) return true;
		return false;
	}

	/**
	 * Resolves user permission based on permission system Id
	 * @param value
	 * @param userId
	 * @returns
	 */
	public async userHasPermissionByKey(value: string, userId?: string): Promise<boolean> {
		// IF LOADED PERMISSION LIST IS UNDEFINED, THEN USER PERMISSION LIST IS NOT LOADED, ONLY IN THOSE CASES RE LOAD THE PERMISSIONS
		if (!this.loadedUserPermissionList) {
			if (userId) {
				await this.init(userId);
			}
		}

		// if user has master permission (e.g. *), then allow true always
		if (this.loadedUserPermissionList && this.loadedUserPermissionList.find((x) => x.permissionSystemID == '*')) {
			return true;
		}
		console.log('value: ', value);
		console.log(this.loadedUserPermissionList);
		if (this.loadedUserPermissionList) {
			for (let i: number = 0; i < this.loadedUserPermissionList.length; i++) if (this.loadedUserPermissionList[i].permissionSystemID === value) return true;
		}
		return false;
	}

	public async deletePermission(request: DeleteRequest): Promise<Result<string>> {
		try {
			return await lastValueFrom(this.apiV2.permissionDelete({ body: request })).then((response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				} else {
					return ResultHelper.failedResponse<string>('Failed to delete permission');
				}
			});
		} catch (error: any) {
			return ResultHelper.failedResponse<string>(error);
		}
	}

	/**
	 * Fetches all roles from data store
	 * @param request
	 * @returns
	 */
	public async fetchPermission(request: EntityGetRequest): Promise<Result<InternalPermissionListItem[]>> {
		return await lastValueFrom(this.apiV2.permissionList({ body: request })).then((response) => {
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<InternalPermissionListItem[]>('Failed to fetch permission');
			}
		});
	}

	/**
	 * Fetches all permissions associated with the give role
	 * @param request
	 * @returns
	 */
	public async fetchPermissionXWikiModuleList(request: EntityGetRequest): Promise<Result<WikiRoleXPermissionItem[]>> {
		let wikiRequest: WikiPermissionGetRequest = {
			entityGetRequest: request,
			isSystemAdmin: true,
		};
		return await lastValueFrom(this.apiV2.getWikiRolePermissionData({ body: wikiRequest })).then((response) => {
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<WikiRoleXPermissionItem[]>('Failed to fetch list');
			}
		});
	}

	public async getInternalPermissionType() {
		try {
			const response = await lastValueFrom(this.apiV2.getInternalPermissionType());
			if (response.isSuccess) {
				return ResultHelper.successResponse(response.data?.items ?? []);
			} else {
				return ResultHelper.failedResponse<InternalPermissionTypeItem[]>('Failed to fetch permission type list');
			}
		} catch (error) {
			return ResultHelper.failedResponse<InternalPermissionTypeItem[]>('Error fetching permission type list');
		}
	}

	// /**
	//  * Adds roles to user
	//  * @param request
	//  * @returns
	//  */
	// public async saveUserXRole(request: InternalUserXRoleSaveList): Promise<Result<string>> {
	// 	return await lastValueFrom(this.apiV2.roleXUserAdd({ body: request })).then((response) => {
	// 		if (response.isSuccess) {
	// 			return ResultHelper.successResponse('Successfully saved roles for user');
	// 		} else {
	// 			return ResultHelper.successResponse('Failed to save roles for users');
	// 		}
	// 	});
	// }
}
