import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Result } from '../models/models';
import { ResultHelper } from '../common/result-extension';

@Injectable({
	providedIn: 'root',
})
export class CommonService {
	constructor() {}

	public bindModelToForm(model: any, form: UntypedFormGroup) {
		const keys = Object.keys(form.controls);

		keys.forEach((key) => {
			model[key] = form.value[key];
			// form.controls[key].valueChanges.subscribe(
			//     (newValue) => {
			//         model[key] = newValue;
			//     }
			// )
		});
		return model;
	}

	async toResultData<T>(func: Function): Promise<Result<T>> {
		try {
			return await func();
		} catch (error: any) {
			console.log('Error logged -----', error);
			return ResultHelper.failedResponse(error);
		}
	}

	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}
}
