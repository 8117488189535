import { Injectable } from '@angular/core';
import { MenuService } from '../../services/menuService';
import { FavoriteMenuModel } from '../models/models';
import { MenuGroupModel, MenuItemModel } from '../../models/models';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/classes';
import { FavoriteMenuUpdateAction } from '../../store/store.actions';
import { CollectionHelper } from '../../../../../goldstar-share/src/app/services/collection.helper';
import { WikiManualService } from '../components/stand-alone/resource-center/services/wiki-manual.service';

@Injectable({
	providedIn: 'root',
})
export class FavoriteMenuService {
	public favoriteMenuBgColorMap: Record<string, string> = {
		0: '#142B48',
		1: '#2D3081',
		2: '#6BCABA',
		3: '#25669F',
		4: '#86C142',
	};

	constructor(private menuService: MenuService, private store: Store<AppState>, private wikiManualService: WikiManualService) {}

	/**
	 * Fetches the user favorite from the remote datastore
	 * @returns
	 */
	public async prepareUserFavoriteMenu(): Promise<FavoriteMenuModel[]> {
		const allMenus = await this.menuService.loadAllMenus();
		if (!allMenus) return [];

		let favoriteMenus: FavoriteMenuModel[] = [];
		allMenus.forEach((menuGroup: MenuGroupModel) => {
			menuGroup.menuItems
				.filter((x) => x.isFavorite)
				.sort((n1: MenuItemModel, n2: MenuItemModel) => {
					if (!n1 || !n1.favoriteOrder || !n2 || !n2.favoriteOrder) return 0;
					if (n1.favoriteOrder > n2.favoriteOrder) {
						return 1;
					}
					if (n1.favoriteOrder < n2.favoriteOrder) {
						return -1;
					}
					return 0;
				})
				.forEach((favoriteMenuModel: MenuItemModel) => {
					let model: FavoriteMenuModel = {
						favoriteOrder: favoriteMenuModel.favoriteOrder ?? 0,
						id: favoriteMenuModel.menuGUID ?? '',
						name: favoriteMenuModel.name ?? '',
						iconName: favoriteMenuModel.imageURL ?? '',
						backgroundColor: `#${favoriteMenuModel.color}` ?? '#fff',
						isEditable: false,
						description: favoriteMenuModel.description ?? '',
						menuItemURL: favoriteMenuModel.isExternalLink ? favoriteMenuModel.externalLinkURL ?? '' : favoriteMenuModel.menuGUID ?? '',
						isExternal: favoriteMenuModel.isExternalLink ?? false,
						externalLink: favoriteMenuModel.externalLinkURL ?? '',
						menuItem: favoriteMenuModel,
						queryParams: favoriteMenuModel.queryParams ? { moduleGUID: `${favoriteMenuModel.queryParams}` } : null,
						isWikiFavorite: favoriteMenuModel.isWikiFavorite,
					};
					favoriteMenus.push(model);
				});
		});

		// Sorting in descending order
		let index = 0;
		favoriteMenus = favoriteMenus.sort((a, b) => (a.favoriteOrder > b.favoriteOrder ? -1 : 1));
		favoriteMenus.forEach((favoriteMenuModel: FavoriteMenuModel) => {
			if (index > 4) index = 0;
			favoriteMenuModel.backgroundColor = this.favoriteMenuBgColorMap[index];
			index++;
		});
		return favoriteMenus;
	}

	/**
	 * Updates favorite menu order
	 * @param updatedFavoriteModels
	 */
	public async saveFavoriteMenu(updatedFavoriteModels: FavoriteMenuModel[]): Promise<void> {
		// Update the Menu Collection for the Side Bar Menu to update
		this.menuService.menuGroupCollection.forEach((menuGroup: MenuGroupModel) => {
			menuGroup.menuItems.forEach((menuItem: MenuItemModel) => {
				if (menuItem) {
					const matchingUpdatedFavoriteMenuItem = updatedFavoriteModels.find((x) => x.id === menuItem.menuGUID);
					if (matchingUpdatedFavoriteMenuItem) {
						menuItem.isFavorite = true;
						menuItem.favoriteOrder = matchingUpdatedFavoriteMenuItem.favoriteOrder;
					} else {
						menuItem.isFavorite = false;
					}
				}
			});
		});
		const allMenuList = CollectionHelper.selectMany(this.menuService.menuGroupCollection, (x) => x.menuItems);

		//Handled for wiki and non - wiki favorite items
		let wikiFavoriteItems = allMenuList.filter((x) => x.isWikiFavorite);
		let nonWikiFavoriteItems = allMenuList.filter((x) => !x.isWikiFavorite);
		if (wikiFavoriteItems.length > 0) {
			wikiFavoriteItems.forEach(async (x) => {
				await this.wikiManualService.updateWiKiFavoriteModule(x.menuGUID ?? '', x.isFavorite ?? false, x.favoriteOrder ?? 0);
			});
		}

		if (nonWikiFavoriteItems.length > 0) await this.menuService.updateFavoriteMenuRemote(nonWikiFavoriteItems);
		if (wikiFavoriteItems.length > 0) {
			await this.menuService.updateWikiFavoriteMenu(wikiFavoriteItems);
		}

		const clonedFavoriteMenus = _.cloneDeep(this.menuService.favoriteMenuCollection);
		this.store.dispatch(FavoriteMenuUpdateAction({ menuList: clonedFavoriteMenus }));
	}
}
