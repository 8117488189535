import { InternalPermissionListItem, InternalRoleListItem, InternalTitleListItem, InternalUserItem, ReferralComments } from '../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { MenuItemModel } from '../../models/models';

export interface MenuGroupUIModel {
	name: string;
	iconName: string;
	selected: boolean;
	items: MenuUIModel[];
}

export interface MenuUIModel {
	urlGUID: string | undefined;
	name: string;
	selected: boolean;
	nestedChild: boolean;
	menuItem: MenuItemModel;
	items: MenuUIModel[];
	childVisible: boolean;
	isExternalLink: boolean;
	externalLinkURL?: string;
}

export interface DropDownMenuModel {
	name: string;
	icon: string;
	onClick: Function;
}

export interface RoutePathConfig {
	rooted: boolean;
	component?: string;
}

export interface MenuGroupIcon {
	ID: string;
	iconName: string;
	iconPrev: string;
}

export interface FavoriteMenuModel {
	id: string;
	name: string;
	backgroundColor: string;
	iconName: string;
	isEditable: boolean;
	favoriteOrder: number;
	description: string;
	isExternal: boolean;
	externalLink: string;
	menuItemURL: string;
	menuItem: MenuItemModel;
	isWikiFavorite?: boolean | null;
	queryParams?: {} | null;
}

export interface InheritedRolesDialogData {
	dataSource: InternalRoleListItem[];
}

export interface RoleXPermissionDialogData {
	dataSource: InternalPermissionListItem[];
}

export interface UserRoleXDialogData {
	dataSource: InternalUserItem[];
}

export interface RoleForUserDataSource {
	dataSource: InternalRoleListItem[];
	userItem: InternalUserItem;
}

export interface RoleXTitleDialogData {
	dataSource: InternalTitleListItem[];
}

export interface UserGroupXUserDialogData {
	dataSource: InternalUserItem[];
}

export interface NotificationMode {
	name: string;
	label: string;
}
export interface ReferralCommentByDate {
	commentDate?: string;
	referralComments?: ReferralComments[];
}
// IT IS USED TO IMPLEMENTED THE COMPONENT NAME AT THE RUN TIME, ALL STANDALONE COMPONENTS SHOULD USE IT
export interface INamedComponent {
	resolveComponentName(): string | undefined;
}

export enum SpinnerState {
	show = 'Show',
	hide = 'Hide',
}

export interface DropdownModel {
	text: string;
	selectedOptions: any[];
	unSelectedOptions: any[];
	dropdownFieldName: string;
	id: string;
}

export enum NotificationMessageType {
	Chat = 'Chat',
	Notification = 'Notification',
}
export interface ChatItem {
	entityGUID: string;
	internalUserGUID: string;
	internalUserName: string;
	chatMessage: string;
	chatDateTime: Date;
}
export interface ChatsDataByDate {
	chatDate?: string;
	chats?: ChatEntityModel[];
}

export interface ChatEntityModel {
	comment?: string | null;
	commentDate?: string | null;
	commentGUID?: string | null;
	entityGUID?: string | null;
	entityXCommentGUID?: string | null;
	userGUID?: string | null;
	userName?: string | null;
}
