import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ToDoService } from '../../../../../../modules/services/todo.service';
import { InternalToDoEventModel } from '../../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { RoutePathResolverService } from '../../../../../../modules/services/route-path-resolver.service';
import { ToDoEventModel } from './shared/to-do-list.models';
import { EventCategory } from './shared/enum';
import { Subscription } from 'rxjs';
import { AppState } from '../../../../../../models/classes';
import { Store } from '@ngrx/store';
import { menuLoadedState, userIdState } from '../../../../../../store/store.selector';
import { LoaderService } from '../../../../../components/loader/service/loader.service';
import { LoaderComponent } from '../../../../../components/loader/loader.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, MatIconModule, LoaderComponent, MatTooltipModule],
	selector: 'app-to-do-list',
	templateUrl: './to-do-list.component.html',
	styleUrls: ['./to-do-list.component.scss'],
})
export class ToDoListComponent implements OnInit, AfterViewChecked, OnDestroy {
	public toDoModelList: InternalToDoEventModel[] = [];

	@ViewChild('toDoListContainer', { static: false }) todoListContainer!: ElementRef;
	@ViewChild('cardBodyContainer', { static: false }) cardBodyContainer!: ElementRef;
	public viewComponentGUID!: string | undefined;
	public addEditToDoListComponentGUID: string | undefined;
	eventCategoryEnum = EventCategory;
	toDoItemSubscription!: Subscription;

	public toDoComponentLoaded!: boolean;
	public toDoSpinner: string = 'ToDoEventSpinner';
	public menuLoadedObserver$!: Subscription;
	public userLoadedObserver$!: Subscription;
	userId: string = '';
	loggedInUserId!: string;
	viewToDoEvent: boolean = true;
	createToDoEvent: boolean = true;
	public elementIdPrefix: string = 'toDoList-';

	@Input() showHeader: boolean = false;
	// Windows update event on form
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.resetFormHeight();
	}
	constructor(
		private renderer: Renderer2,
		private todoService: ToDoService,
		public router: Router,
		public routePathResolverService: RoutePathResolverService,
		public datePipe: DatePipe,
		private loaderService: LoaderService,
		private store: Store<AppState>
	) {
		// SUBSCRIBING TO USER UPDATE EVENT POST SUCCESSFUL AUTHENTICATION
		this.userLoadedObserver$ = this.store.select(userIdState).subscribe(async (userId) => {
			this.toDoComponentLoaded = false;
			this.loaderService.showLoader();
			if (userId) {
				this.userId = userId;
				if (this.loggedInUserId && this.loggedInUserId != userId) {
					this.todoService.initialize(userId);
					this.groupedToDoModelList = await this.todoService.prepareToDoListData();
				}
				this.loggedInUserId = userId ?? '';
			}
		});

		// SUBSCRIBING TO MENU LOADED STATE
		this.menuLoadedObserver$ = this.store.select(menuLoadedState).subscribe(async (menuLoaded) => {
			if (menuLoaded) {
				this.loaderService.showLoader();
				this.toDoComponentLoaded = false;
				const response = await this.todoService.checkIfUserHasPermission();
				if (response.isSuccess && response.data) {
					this.viewToDoEvent = response.data[0];
					this.createToDoEvent = response.data[1];
				}

				if (this.viewToDoEvent) {
					this.viewComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('ToDoEventComponent');
					this.addEditToDoListComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('AddEditToDoListComponent');

					this.groupedToDoModelList = await this.todoService.prepareToDoListData();
				}
				this.loaderService.hideLoader();
				this.toDoComponentLoaded = true;
			}
		});
	}

	ngAfterViewChecked(): void {
		this.resetFormHeight();
	}

	// FOR RESPONSIVENESS
	resetFormHeight() {
		const toDoListContentContainer = this.todoListContainer.nativeElement as HTMLElement;
		const closestUserHistoryContainer = toDoListContentContainer.closest('.user-history-container');

		if (closestUserHistoryContainer?.clientHeight) {
			this.renderer.setStyle(this.cardBodyContainer.nativeElement, 'height', `${closestUserHistoryContainer?.clientHeight - 40}px`);
		}
	}

	async ngOnInit() {
		try {
			this.loaderService.showLoader();
			this.todoService.updateInternalToDoData(this.userId);
			this.toDoItemSubscription = this.todoService.updatedToDoItem.subscribe(async (updatedItem) => {
				if (updatedItem) {
					this.toDoComponentLoaded = false;

					const response = this.todoService.updateToDoList(updatedItem);
					if (response) {
						this.groupedToDoModelList = await this.todoService.prepareToDoListData();
					} else {
						await this.todoService.fetchInternalToDoEventData({ userId: this.userId });
						this.groupedToDoModelList = await this.todoService.prepareToDoListData();
					}
					this.toDoComponentLoaded = true;
				}
			});
		} catch (e) {
			// this.loaderService.hideLoader();
		}
	}

	ngOnDestroy(): void {
		if (this.toDoItemSubscription) this.toDoItemSubscription.unsubscribe();
		this.menuLoadedObserver$.unsubscribe();
		this.userLoadedObserver$.unsubscribe();
	}

	handleActionButtonClicked(actionType: string, showModal: boolean, item: InternalToDoEventModel) {
		this.todoService.showActionModal({
			modalAction: actionType,
			showToDoActionModal: showModal,
			todoItem: item,
		});
	}

	async itemClicked(data: InternalToDoEventModel) {
		if (data.nonToDoEvent) {
			let targetReferralComponent: string | undefined = undefined;
			if (data.eventType?.includes('Fx')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('FxEditComponent');
			else if (data.eventType?.includes('Credit Card')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('CreditCardEditComponent');
			else if (data.eventType?.includes('Deposit Account')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('DepositAccountEditComponent');
			else if (data.eventType?.includes('Domestic Private Client'))
				targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('DomesticPrivateClientEditComponent');
			else if (data.eventType?.includes('International Private Client'))
				targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('InternationalPrivateClientEditComponent');
			else if (data.eventType?.includes('Employee')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('EmployeeEditComponent');
			else if (data.eventType?.includes('Insurance')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('InsuranceEditComponent');
			else if (data.eventType?.includes('Loan')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('LoanEditComponent');
			else if (data.eventType?.includes('Merchant Services')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('MerchantEditComponent');
			else if (data.eventType?.includes('Mortgage')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('MortgageEditComponent');
			else if (data.eventType?.includes('Treasury Management')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('TreasuryEditComponent');
			else if (data.eventType?.includes('Wealth Management')) targetReferralComponent = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('WealthEditComponent');

			if (targetReferralComponent) this.router.navigate(['', targetReferralComponent], { queryParams: { selectedReferralGUID: data.internalToDoEventGUID, isUserView: false } });
		} else {
			this.todoService.selectedToDoItem = data;
			this.router.navigate(['', this.addEditToDoListComponentGUID], { queryParams: { selectedToDoEventGUID: data.internalToDoEventGUID, isUserView: false } });
		}
	}

	get groupedToDoModelList() {
		return this.todoService.groupedToDoModelList;
	}

	set groupedToDoModelList(update: ToDoEventModel[]) {
		this.todoService.groupedToDoModelList = update;
	}

	navigateToLink(data: InternalToDoEventModel) {
		const parts = data.redirectUrl?.split('?');
		if (parts?.length && parts.length > 0) {
			const componentGUID = parts[0];
			const queryParamPart = parts[1];
			const queryParamValueParts = queryParamPart.split('=');
			if (queryParamValueParts.length > 0) {
				const queryParamValue = queryParamValueParts[1];
				const queryParamName = queryParamValueParts[0];
				if (queryParamValue && queryParamName) {
					let params: any = {};
					params[queryParamName] = queryParamValue;
					this.router.navigate(['', componentGUID], { queryParams: params });
				}
			}
		}
	}
}
